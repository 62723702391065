import React, { Fragment, useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import BreadCrumb from "../components/BreadCrumb";
import { webLinks } from "../components/Layout/WebsiteLinks";
import Header from "../components/Layout/Header";
import "../styles/cart.scss";
import CustomButton from "../components/reusable/CustomButton";
import { MdAdd, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RiSubtractFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllCartitemAction,
  clearCartErrors,
  updateCartItemAction,
  clearCartUpdateState,
  removeCartAction,
  clearCartDeleteState,
} from "../redux/action/cartAction";
import Loader from "../components/Loader/Loader";
import { toast } from "react-toastify";
import ButtonLoader from "../components/reusable/ButtonLoader";
import { BsCartX } from "react-icons/bs";
const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quantities, setQuantities] = useState({});
  const [cartItemTotals, setCartItemTotals] = useState({});


   // CART ITEMS LIST STARTS

   const { loading, error, cartItems } = useSelector(
    (state) => state.getAllCartItem
  );

  useEffect(() => {
    dispatch(getAllCartitemAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearCartErrors());
    }
  }, [error, dispatch]);

  // CART ITEMS LIST ENDS



  // INITIAL TOTAL PRIUCE CALCULATION OF EVRY OBJECTS 

  useEffect(() => {
    const initialTotals = {};
    cartItems?.cart?.forEach((item) => {
      const price =
        item?.product?.discountedPrice !== 0
          ? item?.product?.discountedPrice
          : item?.product?.originalPrice;
      initialTotals[item._id] = price * item.numberOfItems;
    });
    setCartItemTotals(initialTotals);
    // eslint-disable-next-line
  }, [cartItems]);

  const updateItemTotal = (itemId, newQuantity) => {
    const item = cartItems?.cart?.find((item) => item._id === itemId);
    const price =
      item?.product?.discountedPrice !== 0
        ? item?.product?.discountedPrice
        : item?.product?.originalPrice;

    setCartItemTotals((prevTotals) => ({
      ...prevTotals,
      [itemId]: price * newQuantity,
    }));
  };

  // INITIAL TOTAL PRIUCE CALCULATION OF EVRY OBJECTS ENDS

  useEffect(() => {
    if (cartItems?.cart) {
      const initialQuantities = {};
      cartItems?.cart.forEach((item) => {
        initialQuantities[item?._id] = item.numberOfItems;
      });
      setQuantities(initialQuantities);
    }
  }, [cartItems?.cart]);

  // UPDATE CART ACTION STARTS
  const handleDecrease = (id) => {
    if (quantities[id] <= 1) {
      toast.error("Quantity cannot be less than 1");
    } else {
      const updatedQuantity = quantities[id] - 1;
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [id]: updatedQuantity,
      }));
      updateItemTotal(id, updatedQuantity);
      dispatch(updateCartItemAction(id, updatedQuantity));
    }
  };

  const handleIncrease = (id) => {
    const updatedQuantity = quantities[id] + 1;
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: updatedQuantity,
    }));
    updateItemTotal(id, updatedQuantity);
    dispatch(updateCartItemAction(id, updatedQuantity));
  };

  const {
    loading: updateLoading,
    error: updateError,
    cartData,
  } = useSelector((state) => state.updateCartItem);

  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
      dispatch(clearCartErrors());
    }
    if (cartData?.success) {
      toast.success(cartData?.message);
      dispatch(clearCartUpdateState());
    }
  }, [dispatch, updateError, cartData]);

  // UPDATE CART ACTION ENDS

 

  // REMOVE CART ITEMS STARTS

  const {
    loading: deleteLoading,
    error: deleteError,
    cartItems: deleteData,
  } = useSelector((state) => state.deleteCartItem);

  const [deleteId, setDeleteId] = useState(null);

  const handleRemoveItem = (id) => {
    setDeleteId(id);
    dispatch(removeCartAction(id));
  };

  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError);
      dispatch(clearCartErrors());
      setDeleteId(null);
    }
    if (deleteData?.success) {
      toast.success(deleteData?.message);
      dispatch(clearCartDeleteState());
      dispatch(getAllCartitemAction());
      setDeleteId(null);
    }
  }, [dispatch, deleteError, deleteData]);

  // REMOVE CART ITEMS ENDS

  const calculateCartTotal = () => {
    return Object.values(cartItemTotals).reduce((total, price) => total + price, 0);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header headerLinks={webLinks} />
          <BreadCrumb pageTitle="Your Cart" />
          <div className="container">
            <section className="cart_wrapper">
              {cartItems?.cart?.length > 0 ? (
                <>
                  <div className="cart_items">
                    {cartItems?.cart?.map((item) => (
                      <div key={item.id} className="cart_item">
                        <button
                          className="delete_btn"
                          onClick={() => handleRemoveItem(item?._id)}
                        >
                          {deleteId === item?._id && deleteLoading ? (
                            <ButtonLoader />
                          ) : (
                            <MdDelete />
                          )}
                        </button>
                        <img
                          src={item?.product?.images[0]}
                          alt={item?.product?.title}
                          className="cart_item_image"
                        />
                        <div className="cart_item_details">
                          <h4>{item?.product?.title}</h4>
                          <p>
                            Price: ₹
                            {item?.product?.discountedPrice !== 0
                              ? item?.product?.discountedPrice
                              : item?.product?.originalPrice}
                          </p>
                          <p>Size: {item?.product?.availableSize[0]}</p>
                        </div>

                        <div className="quantity_btn">
                          <button onClick={() => handleDecrease(item?._id)}>
                            <RiSubtractFill />
                          </button>
                          <p>{quantities[item?._id]}</p>
                          <button onClick={() => handleIncrease(item?._id)}>
                            <MdAdd />
                          </button>
                        </div>

                        <div className="cart_item_total">
                          <h4>Total: ₹{cartItemTotals[item._id] || 0}</h4>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="cart_summary">
                    <div className="details">
                      <h3>Cart Summary</h3>
                      <p>Total Price: ₹{calculateCartTotal()}</p>
                    </div>
                    <div className="btn">
                      <CustomButton
                        className="checkout_btn"
                        width="200px"
                        onClick={() => navigate("/checkout")}
                        title="Proceed to Checkout"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="no_data_card">
                  <span>
                    <BsCartX />
                  </span>
                  <h2>You cart is empty</h2>
                  <Link to="/products">Continue Shopping</Link>
                </div>
              )}
            </section>
          </div>

          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default Cart;
