import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "../styles/breadCrumb.scss";
const BreadCrumb = ({ pageTitle }) => {
  return (
    <Fragment>
      <section className="breadcrum_wrapper">
        <div className="bg"></div>
        <div className="txt">
          <h2>{pageTitle}</h2>
          <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <p>/</p>
          </li>
          <li>
            <p>{pageTitle}</p>
          </li>
        </ul>
        </div>
       
      </section>
    </Fragment>
  );
};

export default BreadCrumb;
