import React from "react";
import "../../styles/slider.scss";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { banner1, banner2, banner3, banner4 } from "../../assests/imageModule/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const BannerSlider = () => {
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", lineHeight: "16px" }}
        onClick={onClick}
      >
        <BiChevronsLeft />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", lineHeight: "16px" }}
        onClick={onClick}
      >
        <BiChevronsRight />
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    centerMode: true,
    centerPadding: "0%",
  };

  return (
    <Slider {...settings} className="banner_comp">
    <img
      className="border img-fluid banner_img"
      src={banner1}
      alt="saaj_banner-1"
    />
    <img
      className="border img-fluid banner_img"
      src={banner2}
      alt="saaj_banner-2"
    />
    <img
      className="border img-fluid banner_img"
      src={banner3}
      alt="saaj_banner-3"
    />
    <img
      className="border img-fluid banner_img"
      src={banner4}
      alt="saaj_banner-4"
    />

   
  </Slider>
  );
};

export default BannerSlider;
