import { BiSolidOffer } from "react-icons/bi";
import { FaHome, FaShoppingBasket } from "react-icons/fa";

export const webLinks = [
  {
    id: 1,
    label: "Home",
    link: "/",
    icon: <FaHome />,
  },
  {
    id: 2,
    label: "Our Story",
    link: "/our-story",
    icon: <BiSolidOffer />,
  },
  {
    id: 4,
    label: "Shop",
    link: "/products",
    icon: <FaShoppingBasket />,
  },

  {
    id: 5,
    label: "Contact Us",
    link: "/contact-us",
  },
];

export const mobLinks = [
  {
    id: 1,
    label: "Home",
    link: "/",
    icon: <FaHome />,
  },

  {
    id: 4,
    label: "Shop",
    link: "/products",
    icon: <FaShoppingBasket />,
  },
];
