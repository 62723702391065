export const faq = [
  {
    question: "What is A2 Cow Ghee?",
    answer:
      "A2 Cow Ghee is made from the milk of cows that produce the A2 beta-casein protein, which is easier to digest and considered healthier compared to regular cow milk.",
  },

  {
    question: "What are the health benefits of A2 Cow Ghee?",
    answer:
      "A2 Cow Ghee is known for its digestive benefits, rich source of vitamins A, D, E, K, and Omega-3 fatty acids. It also boosts immunity and supports healthy joints and skin.",
  },
  {
    question: "Why choose A2 Cow Ghee over regular cow ghee?",
    answer:
      "A2 Cow Ghee is considered healthier due to the absence of the A1 protein, which some people find difficult to digest. It is believed to cause fewer digestive issues and provide better nutritional benefits.",
  },

  {
    question:
      "Can lactose-intolerant people consume A2 Cow Ghee or Buffalo Ghee?",
    answer:
      "Both A2 Cow Ghee and Buffalo Ghee are free from lactose and casein, making them suitable for most people with lactose intolerance.",
  },

  {
    question: "What is the shelf life of A2 Cow Ghee and Buffalo Ghee?",
    answer:
      "Both A2 Cow Ghee and Buffalo Ghee have a long shelf life of up to 12 months when stored in a cool, dry place. They do not require refrigeration.",
  },
  {
    question: "How is your A2 Cow Ghee and Buffalo Ghee made?",
    answer:
      "Our ghee is made using the traditional Bilona method, where the curd is churned into butter and then slow-cooked to obtain pure ghee, ensuring its high quality and nutritional value.",
  },
  {
    question:
      "What are the key differences in taste between A2 Cow Ghee and Buffalo Ghee?",
    answer:
      "A2 Cow Ghee has a mild, slightly nutty flavor, while Buffalo Ghee has a rich, creamy texture with a stronger taste, making it ideal for heavier dishes.",
  },
];
