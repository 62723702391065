import React, { Fragment } from "react";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Footer from "../../components/Layout/Footer";
import "../../styles/policy.scss";
import Header from "../../components/Layout/Header";
const ShippingPolicy = () => {
  return (
    <Fragment>
      <Header headerLinks={webLinks} />
      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Shipping Policy</h4>
            <p>(Last Updated: 03-March-2024)</p>
          </div>
          <div className="shipping_txt">
            <p>
              Thank you for choosing Saaj Farms! We are committed to delivering
              your orders in a timely and efficient manner. Please read our
              shipping policy to understand our processes and expectations.
            </p>
            <p>
              <strong>Processing Time:</strong> Orders are typically processed
              within 1-3 business days after receiving the order. Orders placed
              on weekends or holidays will be processed on the next business
              day. In rare cases of high demand or delays, we will notify you
              regarding extended processing times.
            </p>
            <p>
              <strong>Shipping Charges:</strong> Shipping fees vary based on the
              delivery location and the weight of the products. Charges will be
              calculated and displayed at checkout before you complete your
              purchase. Free shipping may be available on orders over a certain
              amount, as part of promotional offers or other conditions, which
              will be specified on the Site.
            </p>
            <p>
              <strong> Delivery Time :</strong> Estimated delivery times vary
              depending on the shipping destination: Within India: Typically
              delivered within 3-10 business days Please note that these
              delivery times are estimates, and delays may occur due to factors
              beyond our control
            </p>
            <p>
              <strong>Order Tracking:</strong> Once your order is shipped, you
              will receive an email with a tracking number and a link to track
              your package. Please allow up to 24 hours for tracking information
              to update.
            </p>
            <p>
              <strong>Failed Deliveries and Incorrect Addresses :</strong>{" "}
              Please ensure that the shipping address provided is accurate and
              complete. Saaj Farms is not responsible for orders that cannot be
              delivered due to incomplete or incorrect addresses. In the case of
              a failed delivery attempt due to an incorrect address or absence
              of the recipient, additional charges may apply for re-delivery.
            </p>
            <p>
              <strong> Damaged or Lost Packages :</strong> We take every
              precaution to package your items securely. However, if your order
              arrives damaged, please contact us within 48 hours of delivery
              with photographs and details of the damage.
              <br />
              In the unlikely event that your package is lost in transit, we
              will work with our courier partners to investigate and resolve the
              issue
            </p>
            <br />
            <br />
            <p>
              For questions or concerns about your shipment, please contact us:
            </p>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default ShippingPolicy;
