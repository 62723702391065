export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAIL = "UPDATE_CART_FAIL";

export const GET_ALL_CART_REQUEST = "GET_ALL_CART_REQUEST";
export const GET_ALL_CART_SUCCESS = "GET_ALL_CART_SUCCESS";
export const GET_ALL_CART_FAIL = "GET_ALL_CART_FAIL";

export const REMOVE_TO_CART_REQUEST = "REMOVE_TO_CART_REQUEST";
export const REMOVE_TO_CART_SUCCESS = "REMOVE_TO_CART_SUCCESS";
export const REMOVE_TO_CART_FAIL = "REMOVE_TO_CART_FAIL";

export const CLEAR_CART_ERROR = "CLEAR_CART_ERROR";
export const CLEAR_CART_DELETE_STATE = "CLEAR_CART_DELETE_STATE";
export const CLEAR_UPDATE_CART_STATE = "CLEAR_UPDATE_CART_STATE";

export const CLEAR_CART_STATE = "CLEAR_CART_STATE";
