import React, { Fragment } from "react";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Footer from "../../components/Layout/Footer";
import "../../styles/policy.scss";
import Header from "../../components/Layout/Header";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Header headerLinks={webLinks} />

      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Privacy Policy</h4>
            <p>(Last Updated: 03-March-2024)</p>
          </div>
          <p>
            Saaj Farms (“we,” “our,” or “us”) is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you visit our website
            www.saajfarms.in and purchase our products. Please read this Privacy
            Policy carefully. If you do not agree with the terms of this Privacy
            Policy, please do not access the Site.
          </p>
          <div className="body_txt">
            <h6>Information We Collect</h6>
            <p>
              We may collect information about you in a variety of ways,
              including:
            </p>
            <ul>
              <li>
                <strong>Personal information:</strong> When you make a purchase,
                create an account, or contact us, we may collect personal
                information that can identify you, such as your name, email
                address, phone number, shipping address, and payment
                information.
              </li>
              <li>
                <strong>Non-Personal Information:</strong>We may also collect
                non-personal information that does not directly identify you,
                such as demographic information, browser type, device
                information, and usage data.
              </li>
            </ul>
            <h6>How We Use Your Information</h6>
            <p>
              We may use the information we collect for various purposes,
              including to:
            </p>
            <ul>
              <li>Process and fulfill your orders.</li>
              <li>Communicate with you regarding your order or inquiries.</li>
              <li>Improve our products, services, and website experience.</li>
              <li>
                Send you promotional materials or newsletters (if you have opted
                in).
              </li>
              <li>
                Analyze website usage and trends to enhance our offerings.
              </li>
            </ul>
            <h6>Disclosure of Your Information</h6>
            <p>
              We do not sell or rent your personal information to third parties.
              However, we may share your information in the following
              circumstances:
            </p>
            <ul>
              <li>
                <strong>Service Providers:</strong> We may share your
                information with third-party service providers who assist us in
                operating our website, conducting our business, or servicing you
                (e.g., payment processors, shipping companies).
              </li>
              <li>
                <strong>Legal Requirements:</strong> We may disclose your
                information if required to do so by law or in response to valid
                requests by public authorities.
              </li>
            </ul>
            <h6>Cookies and Tracking Technologies</h6>
            <p>
              We may use cookies and similar tracking technologies to enhance
              your experience on our Site. Cookies are small files placed on
              your device that help us understand how you interact with our
              Site. You can choose to accept or decline cookies through your
              browser settings. However, declining cookies may prevent you from
              taking full advantage of the Site.
            </p>
            <h6>Data Security</h6>
            <p>
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. However, no method
              of transmission over the internet or electronic storage is 100%
              secure, and we cannot guarantee absolute security.
            </p>
            <h6>Your Rights</h6>
            <p>
              Depending on your jurisdiction, you may have certain rights
              regarding your personal information, including:
            </p>
            <ul>
              <li>
                The right to access the personal information we hold about you.
              </li>
              <li>
                The right to request correction of any inaccurate or incomplete
                personal information.
              </li>
              <li>
                The right to request deletion of your personal information,
                subject to certain exceptions.
              </li>
              <li>
                The right to withdraw your consent to the processing of your
                personal information (where applicable).
              </li>
              <li>
                To exercise any of these rights, please contact us using the
                information provided in contact us section.
              </li>
            </ul>
            <h6>Third-Party Links</h6>
            <p>
              Our Site may contain links to third-party websites that are not
              operated by us. We have no control over the content and practices
              of these websites, and we encourage you to review their privacy
              policies.
            </p>
            <h6>Children’s Privacy</h6>
            <p>
              Our Site is not intended for children under the age of 13. We do
              not knowingly collect personal information from children. If you
              are a parent or guardian and believe your child has provided us
              with personal information, please contact us, and we will take
              steps to delete such information.
            </p>
            <h6>Changes to This Privacy Policy</h6>
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page with a new effective date. Your continued use of the
              Site after any changes constitutes your acceptance of the updated
              Privacy Policy.
            </p>
            <br />
            <br />
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us at-
              <Link to="!#">help@saajfarms.in</Link>
            </p>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
