export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";

export const GET_ORDER_LIST_REQUEST = "GET_ORDER_LIST_REQUEST";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";
export const GET_ORDER_LIST_FAIL = "GET_ORDER_LIST_FAIL";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL";

export const UPDATE_ORDER_ADDRESS_REQUEST = "UPDATE_ORDER_ADDRESS_REQUEST";
export const UPDATE_ORDER_ADDRESS_SUCCESS = "UPDATE_ORDER_ADDRESS_SUCCESS";
export const UPDATE_ORDER_ADDRESS_FAIL = "UPDATE_ORDER_ADDRESS_FAIL";

export const GET_ORDER_INVOICE_REQUEST = "GET_ORDER_INVOICE_REQUEST";
export const GET_ORDER_INVOICE_SUCCESS = "GET_ORDER_INVOICE_SUCCESS";
export const GET_ORDER_INVOICE_FAIL = "GET_ORDER_INVOICE_FAIL";

export const CLEAR_ORDER_ERRORS = "CLEAR_ORDER_ERRORS";
export const CLEAR_UPDATE_ORDER_STATE = "CLEAR_UPDATE_ORDER_STATE";
export const CLEAR_CANCEL_ORDER_STATE = "CLEAR_CANCEL_ORDER_STATE";
export const CLEAR_ORDER_STATE = "CLEAR_ORDER_STATE";
