import {
    CLEAR_ORDER_ERRORS,
  CLEAR_ORDER_STATE,
  GET_ORDER_INVOICE_FAIL,
  GET_ORDER_INVOICE_REQUEST,
  GET_ORDER_INVOICE_SUCCESS,
  GET_ORDER_LIST_FAIL,
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
} from "../constant/orderConstant";

export const createNewOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
        orderData: {},
      };
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        orderData: action.payload,
      };
    case ORDER_CREATE_FAIL:
      return {
        loading: true,
        error: action.payload,
      };

    case CLEAR_ORDER_ERRORS:
      return {
        orderData:{},
        loading: false,
        error: null,
      };
      case CLEAR_ORDER_STATE:
      return {
        orderData:null,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};


export const getAllOrderListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_LIST_REQUEST:
      return {
        loading: true,
        orderList: {},
      };
    case GET_ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orderList: action.payload,
      };
    case GET_ORDER_LIST_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_ORDER_ERRORS:
      return {
        orderList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const getOrderInvoiceReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ORDER_INVOICE_REQUEST:
        return {
          loading: true,
          invoiceDetails: {},
        };
      case GET_ORDER_INVOICE_SUCCESS:
        return {
          loading: false,
          invoiceDetails: action.payload,
        };
      case GET_ORDER_INVOICE_FAIL:
        return {
          loading: true,
          error: action.payload,
        };
      case CLEAR_ORDER_ERRORS:
        return {
          invoiceDetails: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
