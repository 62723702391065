import React, { Fragment } from "react";
import Header from "../components/Layout/Header";
import { webLinks } from "../components/Layout/WebsiteLinks";
import Footer from "../components/Layout/Footer";
import "../styles/aboutUs.scss";
import BreadCrumb from "../components/BreadCrumb";
import {
  aboutUs,
  buffalo,
  cow,
  honey,
} from "../assests/imageModule/image";
// import CustomButton from "../components/reusable/CustomButton";
const AboutUs = () => {
  return (
    <Fragment>
      <Header headerLinks={webLinks} />
      <BreadCrumb pageTitle="Our Story" />
      <section className="about_us">
        <div className="title">
          <h2>About Us</h2>
        </div>

        <div className="stry">
          <img src={aboutUs} alt="about" />
          <div className="bg"></div>
          <div className="content">
            <div>
              <h4>The birth of saaj farms</h4>
              <p>
                With a newfound sense of purpose, I started Saaj Farms. Our ghee
                is made with milk from our village cows, raised in the clean air
                and open spaces. We ensure every step of production is just as
                it was in our childhood: churning the curd by hand, simmering it
                patiently, and bottling the
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="about_us_blogs">
        <div className="container">
          <div className="title">
            <h2>About our Products</h2>
          </div>
          <div className="blog_cards">
            <div className="card">
              <div className="head">
                <img src={cow} alt="banner1" loading="lazy" />
              </div>
              <div className="crd_bdy">
                <h4>A2 Cow Ghee</h4>
                <span>
                  At Saaj Farms, our A2 Cow Ghee is made from the pure milk of
                  our village's indigenous A2 cows, raised in the green pastures
                  of Haryana. Surrounded by clean air and lush fields, our cows
                  thrive in an environment that’s perfect for producing
                  high-quality milk. Following traditional methods, we carefully
                  churn fresh curd by hand and simmer it slowly, capturing the
                  golden essence of our heritage in each jar. This rich,
                  nutrient-packed ghee embodies the authentic taste and purity
                  of the countryside, preserving the traditional Bilona method
                  that’s been passed down for generations.
                </span>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <img src={buffalo} alt="banner1" loading="lazy" />
              </div>
              <div className="crd_bdy">
                <h4>Buffalo Ghee</h4>
                <span>
                  Our Buffalo Ghee is crafted from the rich, creamy milk of
                  local buffaloes, nurtured in an environment ideal for their
                  well-being. The gentle climate and open fields allow our
                  buffaloes to produce milk that’s full-bodied and flavorful.
                  Following the same traditional Bilona method, we churn and
                  simmer the curd until it transforms into a smooth, golden ghee
                  that’s perfect for enhancing any meal. Saaj Farms’ Buffalo
                  Ghee delivers the taste and aroma that remind us of our
                  childhood kitchens, where food was prepared with patience and
                  care.
                </span>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <img src={honey} alt="banner1" loading="lazy" />
              </div>
              <div className="crd_bdy">
                <h4>Pure Honey</h4>
                <span>
                  At Saaj Farms, we bring you a range of pure honey from
                  different regions of India, each with a unique taste that
                  reflects the local blossoms. From the gentle sweetness of
                  acacia honey from Kashmir to the light floral notes of litchi
                  honey from Bihar’s orchards, every variety embodies the
                  natural beauty of its origin. Each jar of Saaj Farms honey
                  offers a touch of nature’s goodness, capturing the simple,
                  pure flavors of the flowers that inspired it.
                </span>
              </div>
            </div>
          </div>
          {/* <div className="about_button">
            <CustomButton title="Explore" width="90px" />
          </div> */}
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default AboutUs;
