import React, { useState, Fragment } from "react";
import "../styles/contactUs.scss";
import Header from "../components/Layout/Header";
import { webLinks } from "../components/Layout/WebsiteLinks";
import Footer from "../components/Layout/Footer";
import BreadCrumb from "../components/BreadCrumb";
import CustomInput from "../components/reusable/CustomInput";
import { CiLocationOn, CiPhone } from "react-icons/ci";
import { MdOutlineMailOutline } from "react-icons/md";
import CustomButton from "../components/reusable/CustomButton";
const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  return (
    <Fragment>
      <Header headerLinks={webLinks} />
      <BreadCrumb pageTitle="Contact Us" />
      <section className="contact_page">
        <div className="container">
          <div className="get_qry">
            <div className="lft">
              <h2>Let's get in touch</h2>
              <p>We're open for any suggestion or just to have a chat</p>
              <div className="card">
                <span>
                  <CiLocationOn />
                </span>
                <span>
                  ward 5, vpo-lawan, Distt- Mahendergarh, Haryana(123029)
                </span>
              </div>
              <div className="card">
                <span>
                  <CiPhone />
                </span>
                <span>9991559930</span>
              </div>
              <div className="card">
                <span>
                  <MdOutlineMailOutline />
                </span>
                <span>support@saajfarms.com</span>
              </div>
            </div>
            <div className="right">
              <h2>Get In Touch</h2>
              <CustomInput
                value={formData?.fullName}
                onChange={handleInputChange}
                label="Full Name"
                type="text"
                placeholder="Enter your name"
                borderColor="#0c0c0c"
              />
              <CustomInput
                value={formData?.email}
                onChange={handleInputChange}
                label="Email"
                type="email"
                placeholder="Enter email"
                borderColor="#0c0c0c"
              />
              <div className="myInput">
                <label>Message</label>
                <textarea
                  value={formData?.message}
                  onChange={(e) => handleInputChange(e)}
                  label="message"
                  placeholder="Enter your message here"
                  borderColor="#0c0c0c"
                  rows="6"
                />
              </div>
              <div className="send_btn">
                <CustomButton
                  width="160px"
                  title="Send Message"
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default ContactUs;
