import { axiosInstance } from "../../utils/config";
import {
  CLEAR_PRODUCT_ERRORS,
  CLEAR_PRODUCT_STATE,
  GET_ALL_CATEGORY_FAIL,
  GET_ALL_CATEGORY_REQUEST,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_PRODUCTS_FAIL,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
} from "../constant/productConstant";

export const getAllProductAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PRODUCTS_REQUEST,
    });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.get("/all-products", config);

    dispatch({
      type: GET_ALL_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRODUCTS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllCategoryAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CATEGORY_REQUEST,
    });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.get("/all-categories", config);

    dispatch({
      type: GET_ALL_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getProductDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_DETAILS_REQUEST,
    });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.get(`/product-details/${id}`, config);

    dispatch({
      type: GET_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearProductErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const clearProductState = () => async (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_STATE });
};
