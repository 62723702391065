import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import { webLinks } from "../components/Layout/WebsiteLinks";
import BreadCrumb from "../components/BreadCrumb";
import ProductCard from "./product/ProductCard";
import Footer from "../components/Layout/Footer";
import { useNavigate } from "react-router-dom";
import "../styles/products.scss";
import { FaSearch } from "react-icons/fa";
import {
  clearProductErrors,
  getAllCategoryAction,
  getAllProductAction,
} from "../redux/action/productAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import {
  addCartAction,
  clearCartErrors,
  clearCartState,
  getAllCartitemAction,
} from "../redux/action/cartAction";

const ProductPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error, loading, products } = useSelector(
    (state) => state.getAllProduct
  );

  useEffect(() => {
    dispatch(getAllProductAction());
  }, [dispatch]);

  // HANDLE PRODUCTS ERRROR
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearProductErrors());
    }
  }, [dispatch, error, products?.success, products?.message]);

  const {
    loading: categoryLoading,
    error: categoryError,
    categoryList,
  } = useSelector((state) => state.getAllCategory);

  useEffect(() => {
    dispatch(getAllCategoryAction());
  }, [dispatch]);

  useEffect(() => {
    if (categoryError) {
      dispatch(clearProductErrors());
    }
  }, [dispatch, categoryError]);

  // CART ITEMS LIST STARTS

  const {
    loading: cartListLoading,
    error: cartListError,
    cartItems: cartList,
  } = useSelector((state) => state.getAllCartItem);

  useEffect(() => {
    dispatch(getAllCartitemAction());
  }, [dispatch]);

  useEffect(() => {
    if (cartListError) {
      dispatch(clearCartErrors());
    }
  }, [cartListError, dispatch]);

  // CART ITEMS LIST ENDS

  // HANDLE ADD CART ACTION STARTS
  const [loadingId, setLoadingId] = useState(null);
  const {
    loading: cartLoading,
    error: cartError,
    cartItems,
  } = useSelector((state) => state.addCartItem);

  const handleAddToCart = (item) => {
    if (
      cartList?.cart?.some((cartItem) => cartItem?.product?._id === item?._id)
    ) {
      navigate("/shopping-cart");
    } else {
      const numberOfItems = 1;
      setLoadingId(item?._id);
      dispatch(addCartAction(item?._id, numberOfItems, item?.availableSize[0]));
    }
  };

  useEffect(() => {
    if (cartError) {
      toast.error(cartError);
      dispatch(clearCartErrors());
      setLoadingId(null);
    }
    if (cartItems?.success) {
      toast.success(cartItems?.message);
      dispatch(clearCartState());
      setLoadingId(null);
      navigate("/shopping-cart");
    }
  }, [cartError, cartItems, dispatch, navigate]);

  // HANDLE ADD CART ACTION ENDS

  return (
    <Fragment>
      {loading || categoryLoading || cartListLoading ? (
        <Loader />
      ) : (
        <>
          <Header headerLinks={webLinks} />
          <BreadCrumb pageTitle="Products" />
          <div className="container">
            <section className="search_wrapper">
              <input type="text" placeholder="Search by name or category" />
              <button className="btn">
                <FaSearch />
              </button>
            </section>
            <section className="product_page_wrapper">
              <div className="left-sidebar">
                <div className="category_sidebar">
                  <h3>Product Categories</h3>
                  <ul>
                    {categoryList?.categories?.map((ele) => (
                      <li key={ele?._id}>
                        <p>{ele?.title}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="product-grid">
                {products?.products?.map((ele) => {
                  const isInCart = cartList?.cart?.some(
                    (cartItem) => cartItem.product?._id === ele?._id
                  );
                  return (
                    <ProductCard
                      key={ele?._id}
                      id={ele?._id}
                      title={ele?.title}
                      price={ele?.originalPrice}
                      discountPrice={ele?.discountedPrice}
                      rating={ele?.rating}
                      description={ele?.description}
                      numOfReviews={ele?.numOfReviews}
                      cartLoading={cartLoading}
                      checkCart={isInCart}
                      loadingId={loadingId}
                      onCartAdd={() => handleAddToCart(ele)}
                      onClick={() => navigate(`product-detail/${ele?._id}`)}
                    />
                  );
                })}
              </div>
            </section>
          </div>
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default ProductPage;
