import React from "react";
import  Rating  from "@mui/material/Rating";
const CustomRating = ({ val, readOnly, handleChange, precision = 0.1 }) => {
  const options = {
    size: "medium",
    value: val,
    readOnly: readOnly,
    precision: precision,
  };

  return (
    <Rating
      {...options}
      onChange={handleChange}
      sx={{
        "& .MuiRating-iconEmpty svg": {
          color: "#1a8400",
        },
      }}
    />
  );
};

export default CustomRating;
