import React, { Fragment } from "react";
import Footer from "../../components/Layout/Footer";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Header from "../../components/Layout/Header";

const TermsCondition = () => {
  return (
    <Fragment>
      <Header headerLinks={webLinks} />

      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Terms & Conditions</h4>
            <p>(Last Updated: 03-March-2024)</p>
          </div>
          <div className="shipping_txt">
            <p>
              Welcome to Saaj Farms! These terms and conditions outline the
              rules and regulations for the use of Saaj Farms' website, located
              at www.saajfarms.com. By accessing this website, we assume you
              accept these terms and conditions. Do not continue to use Saaj
              Farms if you do not agree to all of the terms and conditions
              stated on this page.
            </p>
            <ul>
              <li>
                <p>
                  Saaj Farms operates an online platform for the sale of milk
                  products, including but not limited to milk, cheese, ghee, and
                  other dairy products.
                </p>
              </li>

              <li>
                <p>
                  By using our website and services, you agree to comply with
                  and be bound by these terms and conditions.
                </p>
              </li>

              <li>
                <p>
                  You must be at least 18 years of age to use this website. By
                  using this website and agreeing to these terms and conditions,
                  you warrant and represent that you are at least 18 years of
                  age.
                </p>
              </li>

              <li>
                <p>
                  To access certain features of our website, you may be required
                  to create an account.
                </p>
              </li>

              <li>
                <p>
                  You are responsible for safeguarding your password. You agree
                  not to disclose your password to any third party and to take
                  sole responsibility for any activities or actions under your
                  account.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default TermsCondition;
