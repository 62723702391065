import React from "react";
import "./loader.scss";

const Loader = () => {
  return (
    <div className="loader_bg">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
export default Loader;
