import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import { webLinks } from "../components/Layout/WebsiteLinks";
import Footer from "../components/Layout/Footer";
import "../styles/checkout.scss";
import { useAuth } from "../context/userContext";
import CustomButton from "../components/reusable/CustomButton";
import { FaPlus } from "react-icons/fa";
import CustomInput from "../components/reusable/CustomInput";
import BreadCrumb from "../components/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCartErrors,
  getAllCartitemAction,
} from "../redux/action/cartAction";
import Loader from "../components/Loader/Loader";
import {
  addNewUserAddress,
  clearErrors,
  clearProfileErrors,
  clearUpdateState,
  userProfile,
} from "../redux/action/userAction";
import { toast } from "react-toastify";
import ButtonLoader from "../components/reusable/ButtonLoader";
import {
  clearOrderError,
  clearOrderState,
  orderCreateAction,
} from "../redux/action/orderAction";

const Checkout = () => {
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const [addAddress, setAddAddress] = useState(false);
  const [selectAddress, setSelectAddress] = useState(null);
  const [cartItemTotals, setCartItemTotals] = useState({});

  const [addressDetails, setAddressDetails] = useState({
    fullAddress: "",
    city: "",
    postalCode: "",
    state: "",
    country: "",
    addressPhone: "",
    recieverName: "",
    email: "",
  });

  const handleAddAddress = () => {
    setAddAddress(!addAddress);
  };

  // CART ITEMS LIST STARTS
  const { loading, error, cartItems } = useSelector(
    (state) => state.getAllCartItem
  );

  // Calculate total price of products
  useEffect(() => {
    const initialTotals = {};
    cartItems?.cart?.forEach((item) => {
      const price =
        item?.product?.discountedPrice !== 0
          ? item?.product?.discountedPrice
          : item?.product?.originalPrice;
      initialTotals[item._id] = price * item.numberOfItems;
    });
    setCartItemTotals(initialTotals);
    // eslint-disable-next-line
  }, [cartItems]);

  useEffect(() => {
    dispatch(getAllCartitemAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearCartErrors());
    }
  }, [error, dispatch]);

  // CART ITEMS LIST ENDS

  const calculateCartTotal = () => {
    return Object.values(cartItemTotals).reduce(
      (total, price) => total + price,
      0
    );
  };

  // GET USER PROFILE STARTS
  const {
    loading: profileLoading,
    error: profileError,
    userInfo: profileInfo,
  } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (profileError) {
      dispatch(clearProfileErrors);
    }
    if (profileInfo?.success) {
      setSelectAddress(profileInfo?.user?.addresses[0]?._id);
    }
  }, [profileError, dispatch, profileInfo]);

  // HANDLE SAVE ADDRESS STARTS
  const {
    addressPhone,
    fullAddress,
    recieverName,
    postalCode,
    state,
    country,
    city,
    email,
  } = addressDetails;

  const {
    error: addressError,
    loading: addressLoading,
    userInfo,
  } = useSelector((state) => state.addNewAddress);

  const handleSaveAddress = () => {
    if (
      addressPhone &&
      fullAddress &&
      recieverName &&
      postalCode &&
      state &&
      country &&
      city &&
      email
    ) {
      dispatch(
        addNewUserAddress(
          email,
          recieverName,
          addressPhone,
          fullAddress,
          postalCode,
          state,
          country,
          city
        )
      );
    } else {
      toast.error("Please input all fields");
    }
  };

  useEffect(() => {
    if (addressError) {
      toast.error(addressError);
      dispatch(clearErrors());
    }
    if (userInfo?.success) {
      toast.success(userInfo?.message);
      dispatch(clearUpdateState());
      dispatch(userProfile());
    }
  }, [dispatch, userInfo, addressError]);

  // HANDLE SAVE ADDRESS ENDS

  // HANDLE CREATE NEW ORDER STARTS
  const {
    loading: orderLoading,
    error: orderError,
    orderData,
  } = useSelector((state) => state.createNewOrder);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (cartItems?.cart) {
      const transformedItems = cartItems.cart.map((item) => ({
        product: item?.product?._id,
        quantity: item?.numberOfItems,
        variant: item?.variety,
        price:
          item?.product?.discountedPrice > 0
            ? item?.product?.discountedPrice
            : item?.product?.originalPrice,
      }));

      setItems(transformedItems);
    }
  }, [cartItems]);

  const handlePlaceOrder = () => {
    dispatch(
      orderCreateAction(
        items,
        auth?.user?._id,
        "Cash on Delivery",
        selectAddress,
        "Standard",
        0,
        0,
        calculateCartTotal(cartItemTotals),
        null
      )
    );
  };

  useEffect(() => {
    if (orderError) {
      toast.error(orderError);
      dispatch(clearOrderError());
    }
    if (orderData?.success) {
      toast.success(orderData?.message);
      dispatch(clearOrderState());
    }
  });

  // HANDLE CREATE NEW ORDER ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header headerLinks={webLinks} />
          <BreadCrumb pageTitle="Checkout" />
          <div className="container">
            <section className="checkout_wrapper">
              <div className="checkout_form">
                <h2>Billing Information</h2>
                <div className="address_list">
                  {profileInfo?.user?.addresses?.map((address) => (
                    <div
                      key={address._id}
                      onClick={() => setSelectAddress(address?._id)}
                      className={`address ${
                        selectAddress === address?._id && "active"
                      }`}
                    >
                      <h4>{address.fullName}</h4>
                      <p>+91{address?.addressPhone}</p>
                      <p>{address.fullAddress}</p>
                      <p>
                        {address.city}, {address.state} - {address.postalCode}
                      </p>
                    </div>
                  ))}
                  {addAddress ? (
                    <div className="new_address">
                      <div className="form">
                        <CustomInput
                          type="email"
                          label="Email"
                          name="email"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              email: e.target.value,
                            })
                          }
                          value={addressDetails?.email}
                          placeholder="Enter email"
                        />
                        <CustomInput
                          type="text"
                          label="Reciever Name"
                          name="recieverName"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              recieverName: e.target.value,
                            })
                          }
                          value={addressDetails?.recieverName}
                          placeholder="Enter reciver name"
                        />
                        <CustomInput
                          type="text"
                          label="Full Address"
                          name="fullAddress"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              fullAddress: e.target.value,
                            })
                          }
                          value={addressDetails?.fullAddress}
                          placeholder="Enter your full address"
                        />

                        <CustomInput
                          type="text"
                          label="City"
                          name="city"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              city: e.target.value,
                            })
                          }
                          value={addressDetails?.city}
                          placeholder="Enter your city"
                        />

                        <CustomInput
                          type="text"
                          label="Postal Code"
                          name="postalCode"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              postalCode: e.target.value,
                            })
                          }
                          value={addressDetails?.postalCode}
                          placeholder="Enter postal code"
                        />

                        <CustomInput
                          type="text"
                          label="State"
                          name="state"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              state: e.target.value,
                            })
                          }
                          value={addressDetails?.state}
                          placeholder="Enter state"
                        />

                        <CustomInput
                          type="text"
                          label="Country"
                          name="country"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              country: e.target.value,
                            })
                          }
                          value={addressDetails?.country}
                          placeholder="Enter country"
                        />

                        <CustomInput
                          type="text"
                          label="Phone"
                          name="addressPhone"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              addressPhone: e.target.value,
                            })
                          }
                          value={addressDetails?.addressPhone}
                          placeholder="Enter phone number"
                        />

                        {/* <button onClick={handleSaveAddress}>Save Address</button> */}
                      </div>
                      <CustomButton
                        title={
                          addressLoading ? <ButtonLoader /> : "Save Address"
                        }
                        onClick={() => handleSaveAddress()}
                        width="100%"
                      />
                    </div>
                  ) : (
                    <div className="add_new_address">
                      <CustomButton
                        onClick={handleAddAddress}
                        width="100%"
                        title="Add New Address"
                        icon={<FaPlus />}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="checkout_summary">
                <h2>Order Summary</h2>
                {cartItems?.cart?.map((ele) => (
                  <div className="summary_item" key={ele?._id}>
                    <div>
                      <img src={ele?.product?.images[0]} alt="product" />
                      <p>
                        <strong>{ele?.product?.title}</strong>
                        <span>Size : {ele?.product?.availableSize[0]}</span>
                        <span>Quantity : {ele?.numberOfItems} </span>
                      </p>
                    </div>
                    <p>
                      <span>
                        ₹
                        {ele?.product?.discountedPrice !== 0
                          ? ele?.product?.discountedPrice
                          : ele?.product?.originalPrice}
                        * {ele?.numberOfItems}
                      </span>
                      <strong>₹{cartItemTotals[ele._id] || 0}</strong>
                    </p>
                  </div>
                ))}
                <div className="shipping_cost">
                  <span>Shipping Cost</span>
                  <span>
                    {calculateCartTotal() < 4999 ? `₹ ${99}` : "Free Shipping"}
                  </span>
                </div>
                <div className="summary_total">
                  <span>Total</span>
                  <span>
                    ₹
                    {calculateCartTotal() +
                      (calculateCartTotal() < 4999 ? 99 : 0)}
                    <br />
                    <span>(incl all taxes.)</span>
                  </span>
                </div>
                <button
                  className="place_order_btn"
                  onClick={() => handlePlaceOrder()}
                >
                  {orderLoading || profileLoading ? (
                    <ButtonLoader />
                  ) : (
                    "Place Order"
                  )}
                </button>
              </div>
            </section>
          </div>
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default Checkout;
