import {
  ADD_TO_CART_FAIL,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  CLEAR_CART_DELETE_STATE,
  CLEAR_CART_ERROR,
  CLEAR_CART_STATE,
  CLEAR_UPDATE_CART_STATE,
  GET_ALL_CART_FAIL,
  GET_ALL_CART_REQUEST,
  GET_ALL_CART_SUCCESS,
  REMOVE_TO_CART_FAIL,
  REMOVE_TO_CART_REQUEST,
  REMOVE_TO_CART_SUCCESS,
  UPDATE_CART_FAIL,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
} from "../constant/cartConstant";

export const getAllCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CART_REQUEST:
      return {
        loading: true,
        cartItems: {},
      };

    case GET_ALL_CART_SUCCESS:
      return {
        loading: false,
        cartItems: action.payload,
      };
    case GET_ALL_CART_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_CART_ERROR:
      return {
        cartItems: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const addCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_CART_REQUEST:
      return {
        loading: true,
        cartItems: {},
      };

    case ADD_TO_CART_SUCCESS:
      return {
        loading: false,
        cartItems: action.payload,
      };
    case ADD_TO_CART_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_CART_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CART_STATE:
      return {
        cartItems: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const updateCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CART_REQUEST:
      return {
        loading: true,
        cartData: {},
      };

    case UPDATE_CART_SUCCESS:
      return {
        loading: false,
        cartData: action.payload,
      };
    case UPDATE_CART_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_CART_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_UPDATE_CART_STATE:
      return {
        cartData: {},
        loading: false,
      };

    default:
      return state;
  }
};

export const deleteCartItemReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_TO_CART_REQUEST:
      return {
        loading: true,
        cartItems: {},
      };

    case REMOVE_TO_CART_SUCCESS:
      return {
        loading: false,
        cartItems: action.payload,
      };
    case REMOVE_TO_CART_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_CART_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_CART_DELETE_STATE:
      return {
        cartItems: {},
        loading: false,
      };

    default:
      return state;
  }
};
