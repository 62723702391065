import React from "react";
import Slider from "react-slick";
import "../styles/testimonialSlider.scss";
import {
  testimonial1,
  testimonial2,
  testimonial3,
} from "../assests/imageModule/image";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const testimonials = [
  {
    name: "Kajal Rana",
    quote:
      "Saaj Farms has the best dairy products I have ever tasted. Highly recommend to buy!",
    image: testimonial1,
  },
  {
    name: "Samritha Jain",
    quote:
      "I love the fresh cow ghee from Saaj Farms. It’s always so creamy and delicious!",
    image: testimonial2,
  },
  {
    name: "Sonakshi Malik",
    quote:
      "Their cheese selection is incredible. Each one is unique and flavorful and very good in taste",
    image: testimonial3,
  },
];


const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <GrFormPrevious />
      </div>
    );
  };
  
  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <GrFormNext />
      </div>
    );
  };

const TestimonialSlider = () => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-slider">
      <div className="title">
        <h2>What people feel about Saaj Farms products</h2>
      </div>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <div>
              <img src={testimonial.image} alt={testimonial.name} />
              <blockquote>{testimonial.quote}</blockquote>
              <p>- {testimonial.name}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
