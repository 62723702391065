import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  getAllCategoryReducer,
  getAllProductReducer,
  getProductDetailReducer,
} from "./reducer/productReducer";
import {
  addNewAddressReducer,
  updateAddressReducer,
  userLoginReducer,
  userProfileReducer,
} from "./reducer/userReducer";
import {
  addCartItemReducer,
  deleteCartItemReducer,
  getAllCartItemReducer,
  updateCartItemReducer,
} from "./reducer/cartReducer";
import {
  createNewOrderReducer,
  getAllOrderListReducer,
  getOrderInvoiceReducer,
} from "./reducer/orderReducer";

// get user from localStorage
const userFromLocalStorage = localStorage.getItem("saajToken")
  ? JSON.parse(localStorage.getItem("saajToken"))
  : {};

const reducer = combineReducers({
  getAllProduct: getAllProductReducer,
  getProductDetail: getProductDetailReducer,
  getAllCategory: getAllCategoryReducer,
  userLogin: userLoginReducer,
  userProfile: userProfileReducer,
  updateProfile: updateAddressReducer,
  addNewAddress:addNewAddressReducer,
  addCartItem: addCartItemReducer,
  updateCartItem: updateCartItemReducer,
  deleteCartItem: deleteCartItemReducer,
  getAllCartItem: getAllCartItemReducer,

  createNewOrder:createNewOrderReducer,
  getAllOrderList: getAllOrderListReducer,
  getOrderInvoice: getOrderInvoiceReducer,
});

// MIDDLEWARE // THUNK
const middleware = [thunk];

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromLocalStorage,
  },
};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
