import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: rgb(251 121 116);
  color: #f2f2f2;
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid rgb(251 121 116);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  height: 35px;
  width: ${(props) => props.width};
  transition: 0.4s;

  &:hover {
    background-color: #ffff;
    color: rgb(251 121 116);
    border: 1px solid rgb(251 121 116);
  }
`;

const CustomButton = ({
  className,
  title,
  onClick,
  icon,
  disabled,
  width = "260px",
  preIcon,
  tooltip,
}) => {
  return (
    <Button
      className={`${className}`}
      onClick={onClick}
      width={width}
      disabled={disabled}
      title={tooltip}
    >
      {preIcon && (
        <span
          style={{
            marginRight: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {preIcon}
        </span>
      )}{" "}
      {title} {icon}
    </Button>
  );
};

export default CustomButton;
