import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import { webLinks } from "../components/Layout/WebsiteLinks";
import Footer from "../components/Layout/Footer";
import "../styles/profile.scss";
import CustomInput from "../components/reusable/CustomInput";
import { useAuth } from "../context/userContext";
import BreadCrumb from "../components/BreadCrumb";
import { FaRegEdit, FaRegUserCircle } from "react-icons/fa";
import moment from "moment/moment";
import CustomButton from "../components/reusable/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearUpdateState,
  updateUserAddress,
} from "../redux/action/userAction";
import ButtonLoader from "../components/reusable/ButtonLoader";
import { toast } from "react-toastify";
import {
  clearOrderError,
  downloadOrderInvoice,
  getAllOrderAction,
} from "../redux/action/orderAction";
import Loader from "../components/Loader/Loader";
const Profile = () => {
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("profile");
  const [profileData, setProfileData] = useState({
    email: auth?.user?.email,
    name: auth?.user?.name,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const [editAdd, setEditAdd] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
    fullAddress: "",
    city: "",
    postalCode: "",
    state: "",
    country: "",
    addressPhone: "",
    recieverName: "",
  });

  const handleAddressClick = (address) => {
    setEditAdd(!editAdd);
    setProfileData({
      email: auth?.user?.email,
    });
    setAddressDetails({
      id: address?._id,
      fullAddress: address.fullAddress,
      recieverName: auth?.user?.name,
      city: address.city,
      postalCode: address.postalCode,
      state: address.state,
      country: address.country,
      addressPhone: address.addressPhone,
    });
  };

  const { email } = profileData;
  const {
    id,
    addressPhone,
    fullAddress,
    recieverName,
    postalCode,
    state,
    country,
    city,
  } = addressDetails;

  // UPDATE ADDRESS FUNCTION STARTS
  const { error, loading, userInfo } = useSelector(
    (state) => state.updateProfile
  );

  const handleUpdateAddress = () => {
    dispatch(
      updateUserAddress(
        email,
        recieverName,
        addressPhone,
        fullAddress,
        postalCode,
        state,
        country,
        city,
        id
      )
    );
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (userInfo?.success) {
      toast.success(userInfo?.message);
      setEditAdd(false);
      dispatch(clearUpdateState());
    }
  }, [error, dispatch, userInfo]);

  // UPDATE ADDRESS FUNCTION ENDS

  // GET ORDER LIST STARTS

  const {
    loading: orderLoading,
    error: orderError,
    orderList,
  } = useSelector((state) => state.getAllOrderList);

  const allOrders = orderList?.orders;

  useEffect(() => {
    dispatch(getAllOrderAction());
  }, [dispatch]);

  useEffect(() => {
    if (orderError) {
      dispatch(clearOrderError());
    }
  }, [orderError, dispatch]);

  // GET ORDER LIST ENDS

  // DOWNLOAD INVOICE STARTS
  const {
    loading: invoiceLoading,
    error: invoiceError,
    invoiceDetails,
  } = useSelector((state) => state.getOrderInvoice);

  const handleDownloadInvoice = (id) => {
    dispatch(downloadOrderInvoice(id));
  };

  useEffect(() => {
    if (invoiceError) {
      toast.error(invoiceError);
      dispatch(clearOrderError());
    }
  }, [dispatch, invoiceError, invoiceDetails]);
  

  // DOWNLOAD INVOICE STARTS

  return (
    <Fragment>
      {orderLoading ? (
        <Loader />
      ) : (
        <>
          <Header headerLinks={webLinks} />
          <BreadCrumb pageTitle="My Account" />
          <div className="container">
            <section className="profile_wrapper">
              <ul className="tabs_list">
                <div className="profile_pic">
                  <span>
                    <FaRegUserCircle />
                  </span>
                  <p>
                    <span>{auth?.user?.name}</span>
                    <span>
                      {moment(auth?.user?.updatedAt).format("DD-MM-YYYY")}
                    </span>
                  </p>
                </div>
                <li
                  onClick={() => setActiveTab("profile")}
                  className={activeTab === "profile" ? "active" : ""}
                >
                  <p>address details</p>
                </li>
                <li
                  onClick={() => setActiveTab("orders")}
                  className={activeTab === "orders" ? "active" : ""}
                >
                  <p>orders</p>
                </li>
              </ul>
              <div className="all_details">
                {activeTab === "profile" && (
                  <div className="personal_detail">
                    <h2>Address Details</h2>
                    <div className="form">
                      <CustomInput
                        type="text"
                        label="Full Name"
                        name="name"
                        readOnly={true}
                        onChange={handleInputChange}
                        value={
                          profileData?.name
                            ? profileData?.name
                            : auth?.user?.name
                        }
                        placeholder="Enter your full name"
                      />

                      <CustomInput
                        type="email"
                        label="Email Address"
                        name="email"
                        readOnly={true}
                        onChange={handleInputChange}
                        value={
                          profileData?.email
                            ? profileData?.email
                            : auth?.user?.email
                        }
                        placeholder="Enter your email"
                      />
                    </div>
                    <h4>Save Address List</h4>
                    {auth?.user?.addresses?.map((address) => (
                      <div key={address._id} className="address_box">
                        <button
                          onClick={() => handleAddressClick(address)}
                          className="edit_btn"
                        >
                          <FaRegEdit />
                        </button>
                        <p>{address.fullName}</p>
                        <p>+91{address?.addressPhone}</p>
                        <p>{address.fullAddress}</p>
                        <p>
                          {address.city}, {address.state} - {address.postalCode}
                        </p>
                      </div>
                    ))}
                    {editAdd && (
                      <div className="form">
                        <CustomInput
                          type="text"
                          label="Reciever Name"
                          name="recieverName"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              recieverName: e.target.value,
                            })
                          }
                          value={addressDetails?.recieverName}
                          placeholder="Enter reciver name"
                        />
                        <CustomInput
                          type="text"
                          label="Full Address"
                          name="fullAddress"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              fullAddress: e.target.value,
                            })
                          }
                          value={addressDetails?.fullAddress}
                          placeholder="Enter your full address"
                        />

                        <CustomInput
                          type="text"
                          label="City"
                          name="city"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              city: e.target.value,
                            })
                          }
                          value={addressDetails?.city}
                          placeholder="Enter your city"
                        />

                        <CustomInput
                          type="text"
                          label="Postal Code"
                          name="postalCode"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              postalCode: e.target.value,
                            })
                          }
                          value={addressDetails?.postalCode}
                          placeholder="Enter postal code"
                        />

                        <CustomInput
                          type="text"
                          label="State"
                          name="state"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              state: e.target.value,
                            })
                          }
                          value={addressDetails?.state}
                          placeholder="Enter state"
                        />

                        <CustomInput
                          type="text"
                          label="Country"
                          name="country"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              country: e.target.value,
                            })
                          }
                          value={addressDetails?.country}
                          placeholder="Enter country"
                        />

                        <CustomInput
                          type="text"
                          label="Phone"
                          name="addressPhone"
                          onChange={(e) =>
                            setAddressDetails({
                              ...addressDetails,
                              addressPhone: e.target.value,
                            })
                          }
                          value={addressDetails?.addressPhone}
                          placeholder="Enter phone number"
                        />

                        {/* <button onClick={handleSaveAddress}>Save Address</button> */}
                      </div>
                    )}
                    {editAdd && (
                      <div className="update_btn">
                        <CustomButton
                          onClick={handleUpdateAddress}
                          width="160px"
                          className="login_btn"
                          title={loading ? <ButtonLoader /> : "Save Address"}
                        />
                      </div>
                    )}
                  </div>
                )}
                {activeTab === "orders" && (
                  <div className="order_list">
                    <h2>order history</h2>
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Product Details</th>
                          <th>Total Price</th>
                          <th>Payment Method</th>
                          <th>Order Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allOrders?.map((ele, key) => (
                          <tr key={ele?._id}>
                            <td>{ele?._id}</td>
                            <td>
                              {ele?.items?.map((it, idx) => (
                                <p key={it?._id}>
                                  <span>{it?.product?.title}</span>{" "}
                                  <span>({it?.quantity}x)</span>
                                </p>
                              ))}
                            </td>
                            <td>Rs.{ele?.totalPrice}</td>
                            <td>{ele?.paymentMethod}</td>
                            <td>
                              {moment(ele?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td>{ele?.status}</td>
                            <td>
                              <button
                                onClick={() => handleDownloadInvoice(ele?._id)}
                              >
                                {invoiceLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  "Download Invoice"
                                )}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </section>
          </div>
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default Profile;
