import React from "react";
import { product } from "../../assests/imageModule/image";
import "../../styles/productCard.scss";
import CustomButton from "../../components/reusable/CustomButton";
import CustomRating from "../../components/reusable/CustomRating";
import ButtonLoader from "../../components/reusable/ButtonLoader";

const ProductCard = ({
  id,
  title,
  price,
  rating,
  description,
  numOfReviews,
  discountPrice,
  cartLoading,
  checkCart,
  loadingId,
  onCartAdd,
  onClick,
}) => {
  const ratingRoundOff = (value) => {
    var multiplier = Math.pow(10, 1);
    return Math.round(value * multiplier) / multiplier;
  };

  return (
    <div className="custom_product_card">
      <div className="img_wrap" onClick={onClick}>
        <img src={product} alt={product} />
      </div>
      <div className="details">
        <h4 onClick={onClick}>{title}</h4>
        <p className="desc" onClick={onClick}>
          {description?.slice(0, 26) + description?.length > 26 ? "..." : ""}
        </p>
        <div className="rating" onClick={onClick}>
          <CustomRating val={ratingRoundOff(rating)} readOnly={true} />
          <strong>({numOfReviews} reviews)</strong>
        </div>

        <p className="price">
          <span>
            <strike>
              {/* रु */}
              Rs. {price}
            </strike>
            <strong>Rs. {discountPrice}</strong>
          </span>
          <span className="pre">MRP (Incl. of all taxes)</span>
        </p>

        <CustomButton
          title={
            loadingId === id && cartLoading ? (
              <ButtonLoader />
            ) : checkCart ? (
              "Added To Cart"
            ) : (
              "Buy Now"
            )
          }
          onClick={onCartAdd}
          width="100%"
        />
      </div>
    </div>
  );
};

export default ProductCard;
