import {
  CLEAR_PRODUCT_ERRORS,
  GET_ALL_CATEGORY_FAIL,
  GET_ALL_CATEGORY_REQUEST,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_PRODUCTS_FAIL,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
} from "../constant/productConstant";

export const getAllProductReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_REQUEST:
      return {
        loading: true,
        products: {},
      };

    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case GET_ALL_PRODUCTS_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_PRODUCT_ERRORS:
      return {
        products: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const getAllCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY_REQUEST:
      return {
        loading: true,
        categoryList: {},
      };

    case GET_ALL_CATEGORY_SUCCESS:
      return {
        loading: false,
        categoryList: action.payload,
      };
    case GET_ALL_CATEGORY_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_PRODUCT_ERRORS:
      return {
        categoryList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const getProductDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAILS_REQUEST:
      return {
        loading: true,
        product: {},
      };

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        product: action.payload,
      };
    case GET_PRODUCT_DETAILS_FAIL:
      return {
        loading: true,
        error: action.payload,
      };
    case CLEAR_PRODUCT_ERRORS:
      return {
        product: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
