import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/policy/PrivacyPolicy";
import ShippingPolicy from "./pages/policy/ShippingPolicy";
import RefundPolicy from "./pages/policy/RefundPolicy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsCondition from "./pages/policy/TermsCondition";
import Login from "./pages/Login";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import ProductPage from "./pages/Product";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import ProductDetail from "./pages/product/ProductDetail";
import Profile from "./pages/Profile";
import { useSelector } from "react-redux";
import Loader from "./components/Loader/Loader";
const App = () => {
  const { loading, isAuthenticated, userInfo } = useSelector(
    (state) => state.userProfile
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />

          <Routes>
            <Route exact path="/" element={<Home />} />

            <Route path="/authentication" element={<Login />} />


            {isAuthenticated && <Route path="/profile" element={<Profile />} />}
            <Route path="/our-story" element={<AboutUs />} />

            <Route path="/contact-us" element={<ContactUs />} />

            <Route path="/products" element={<ProductPage />} />
            <Route path="/product-detail/:id" element={<ProductDetail />} />

            <Route path="/shopping-cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/shipping-policy" element={<ShippingPolicy />} />

            <Route path="/refund-policy" element={<RefundPolicy />} />

            <Route path="/term-condition" element={<TermsCondition />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
