import axios from "axios";
import {
  CLEAR_ERRORS,
  CLEAR_PROFILE_ERRORS,
  CLEAR_STATE,
  CLEAR_USER_LOADING,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  CLEAR_UPDATE_STATE,
  USER_LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ADD_NEW_ADDRESS_SUCCESS,
  ADD_NEW_ADDRESS_FAIL,
  ADD_NEW_ADDRESS_REQUEST,
} from "../constant/userConstant";
import { axiosInstance } from "../../utils/config";

//   FOR USER REGISTRATION
export const userRegisterAction =
  (name, email, password, mobile) => async (dispatch, getState) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_LIVE}/register`,
        {
          name,
          email,
          password,
          mobile,
        },
        config
      );
      dispatch({
        type: REGISTER_SUCCESS,
        payload: data,
      });
      localStorage.setItem(
        "saajToken",
        JSON.stringify(getState().userLogin?.userInfo)
      );
    } catch (error) {
      dispatch({
        type: REGISTER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const login = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_LIVE}/login-with-pass`,
      {
        email,
        password,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(
      "saajToken",
      JSON.stringify(getState().userLogin?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const googleLogin = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_LIVE}/googlelogin`,
      {},
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(
      "saajToken",
      JSON.stringify(getState().userLogin?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    localStorage.removeItem("saajToken");

    dispatch({ type: CLEAR_STATE });

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("saajToken")
      ? JSON.parse(localStorage.getItem("saajToken"))
      : "";

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorageToken?.token}`;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_LIVE}/me`,
      config
    );

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER PROFILE
export const updateUserAddress =
  (
    addressEmail,
    fullName,
    addressPhone,
    fullAddress,
    postalCode,
    state,
    country,
    city,
    id
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_UPDATE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("saajToken")
        ? JSON.parse(localStorage.getItem("saajToken"))
        : "";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_LIVE}/update-address/${id}`,
        {
          addressEmail,
          fullName,
          addressPhone,
          fullAddress,
          postalCode,
          state,
          country,
          city,
        },
        config
      );

      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROFILE_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// ADD NEW ADDRESS

export const addNewUserAddress =
  (
    addressEmail,
    fullName,
    addressPhone,
    fullAddress,
    postalCode,
    state,
    country,
    city
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_NEW_ADDRESS_REQUEST,
      });

      const localStorageToken = localStorage.getItem("saajToken")
        ? JSON.parse(localStorage.getItem("saajToken"))
        : "";

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorageToken?.token}`;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_LIVE}/add-address`,
        {
          addressEmail,
          fullName,
          addressPhone,
          fullAddress,
          postalCode,
          state,
          country,
          city,
        },
        config
      );

      dispatch({
        type: ADD_NEW_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_NEW_ADDRESS_FAIL,
        payload: error.response.data.message,
      });
    }
  };


// FORGOT PASSWORD
export const requestForgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const { data } = await axiosInstance.put("/forgot-password", { email });

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// RESET PASSWORD
export const resetPasswordAction = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    const { data } = await axiosInstance.put("/reset-password", {
      token,
      password,
    });

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearProfileErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE_ERRORS });
  localStorage.removeItem("saajToken");
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};

// CLEAR USER LOADING ACTION FUNCTION
export const clearLoading = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_LOADING });
};
